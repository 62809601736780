import './App.css';
import "@aws-amplify/ui-react/styles.css";
import React, { useState, useEffect, FormEvent } from "react";
import _, { map } from 'underscore';
import logo from './artcafe-logo.png';
import al from './artcafe-logo.png';

// import from Amplify library
import { generateClient } from 'aws-amplify/api';
import { createTodo, updateTodo, deleteTodo } from './graphql/mutations';

// import query definition
import { listPosts } from './graphql/queries'

// src/App.js, import the withAuthenticator component
import { withAuthenticator } from '@aws-amplify/ui-react'

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';


function App({ signOut, user }) {

  const [posts, setPosts] = useState([])
  const client = generateClient();
  
  useEffect(() => {
    fetchPosts();
  }, []);

  async function fetchPosts() {
    try {
      const postData = await client.graphql({ query: listPosts });
      setPosts(postData.data.listPosts.items)
    } catch (err) {
      console.log({ err })
    }
  }


  return (
    <div>
      <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign out</button>
      
      {
        posts.map(post => (
          <div key={post.id}>
            <h3>{post.name}</h3>
            <p>{post.location}</p>
          </div>
        ))
      }
    </div>

  );

}

export default withAuthenticator(App);
